<template>
  <b-form-group v-bind="commonFormGroupProps">
    <b-form-file
        :value="value"
        @input="$emit('input', $event)"
        :placeholder="item.placeHolder || 'Choose a file or drop it here...'"
        :drop-placeholder="item.dropPlaceHolder || 'Drop file here...'"
    />
  </b-form-group>
</template>

<script>
import { formMixin } from '@/mixins/formMixin'

export default {
  mixins: [formMixin],
  props: ['item', 'value'],
  computed: {
    commonFormGroupProps () {
      return {
        ...this.getCommonFormGroupProps()
      }
    }
  }
}
</script>