<template>
  <b-form-group v-bind="commonFormGroupProps">
    <vue-autosuggest
        :value="item.value || suggestionValue"
        :suggestions="formattedSuggestions"
        :input-props="inputProps"
        :get-suggestion-value="getSuggestionValue"
        :section-configs="sectionConfigs"
        @selected="handleSelected"
        @input="handleInput"
        @focus="highlightSelection"
        :key="autoSuggestKey"
    >
      <template slot-scope="{suggestion}">
        <div class="d-flex align-items-center">
          <slot :name="`autosuggest-item(${filterKey})`" :item="suggestion.item">
            {{ suggestion.item[item.keyText] }}
            <span v-if="item.secondaryKeyText" class="text-muted ml-2">
              ({{ suggestion.item[item.secondaryKeyText] }})
            </span>
          </slot>
        </div>
      </template>
    </vue-autosuggest>
  </b-form-group>
</template>

<script>
import { VueAutosuggest } from 'vue-autosuggest'
import { formMixin } from '@/mixins/formMixin'

export default {
  components: { VueAutosuggest },
  mixins: [formMixin],
  props: {
    item: { type: Object, required: true },
    filterKey: { type: String, required: true },
    suggestions: { type: Object, default: () => ({}) }
  },
  computed: {
    commonFormGroupProps () {
      return {
        ...this.getCommonFormGroupProps()
      }
    },
    formattedSuggestions () {
      const defaultData = Array.isArray(this.item.data) ? this.item.data : []
      return this.suggestions[this.filterKey] || [{ data: defaultData }]
    },
    suggestionValue () {
      const defaultValue = this.item.defaultValue || ''
      const safeData = Array.isArray(this.item.data) ? this.item.data : []

      let found = safeData.find(item => String(item[this.item.keyValue]) === String(defaultValue))

      if (!found) {
        found = this.formattedSuggestions[0].data.find(item => String(item[this.item.keyValue]) === String(defaultValue))
      }

      if (!found) return defaultValue

      const primaryText = found[this.item.keyText] ?? ''
      const secondaryText = found[this.item.secondaryKeyText]

      return secondaryText ? `${primaryText} (${secondaryText})` : primaryText || defaultValue
    },
    inputProps () {
      return {
        id: `autosuggest__input_${this.filterKey}`,
        class: 'form-control',
        placeholder: this.item.placeHolder || '',
        disabled: this.item.isDisabled || false
      }
    },
    // eslint-disable-next-line vue/return-in-computed-property
    sectionConfigs () {
      if (this.item.limit) {
        return {
          'default': {
            limit: this.item.limit
          }
        }
      }
    },
    autoSuggestKey () {
      return `autosuggest-${this.item.refresh_uuid ? `${this.filterKey}-${this.item.refresh_uuid}` : this.filterKey}`
    }
  },
  methods: {
    getSuggestionValue (suggestion) {
      const primaryText = suggestion.item[this.item.keyText] ?? ''
      const secondaryText = suggestion.item[this.item.secondaryKeyText]

      return primaryText && secondaryText ? `${primaryText} (${secondaryText})` : primaryText || secondaryText
    },

    handleSelected (data) {
      this.$emit('input', data.item[this.item.keyValue])

      if (this.item.change) {
        this.$emit('change', data.item[this.item.keyValue])
      }
    },
    handleInput (query) {
      if (this.item.customGenerateSuggestion) {
        this.$emit('find', query)
      } else {
        this.generateSuggestion(query, this.filterKey, this.item)
      }
    },
    generateSuggestion (query, key, item) {
      const dataSource = Array.isArray(item.data) ? item.data : []
      const filteredData = dataSource.filter(data => this.calculateRelevance(data, query, item) > 0
      ).sort((a, b) => this.calculateRelevance(b, query, item) -
            this.calculateRelevance(a, query, item)
      )

      this.$emit('update:suggestions', {
        ...this.suggestions,
        [key]: [{ data: filteredData }]
      })
    },
    calculateRelevance (data, searchInput, item) {
      let relevance = 0
      const searchTerm = searchInput.toLowerCase()

      if (item.findKeys) {
        relevance = item.findKeys.reduce((acc, key) => {
          const val = data[key]?.toString().toLowerCase()
          if (val === searchTerm) acc += 2
          if (val?.includes(searchTerm)) acc += 1
          return acc
        }, 0)
      } else {
        const mainText = data[item.keyText]?.toString().toLowerCase()
        if (mainText === searchTerm) relevance += 2
        if (mainText?.includes(searchTerm)) relevance += 1
      }

      return relevance
    },
    highlightSelection (e) {
      e.target.select()
    }
  }
}
</script>