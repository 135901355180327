import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
import GlobalMixin from './mixins/global-mixins'
import { ToastPlugin, ModalPlugin, BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import { extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import './directives'

// // Install VeeValidate rules
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

import CountryFlag from 'vue-country-flag'
Vue.component('country-flag', CountryFlag)

import './main/constants'
import './main/filters'

import {PrimeSurveys} from './main/primesurveys'
Vue.use(PrimeSurveys)

import axiosObject from './main/axios.js'
Vue.prototype.$http = axiosObject

import axiosChatObject from './main/axiosChat.js'
Vue.prototype.$httpChat = axiosChatObject

import router from './main/router'
import store from './store/index'
store.dispatch('auth/init')

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)
// Add global mixin
window.VueComp = {
  Others: []
}
Vue.mixin(GlobalMixin)
// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

import SupplierElement from '@/views/elements/SupplierElement.vue'
import CountryElement from './views/elements/CountryElement.vue'
import UserElement from './views/elements/UserElement.vue'
import BaseForm from '@ps_main/plugins/forms/BaseForm.vue'
import FormElement from '@ps_main/plugins/forms/FormElement.vue'
import BaseTable from '@ps_main/custom-components/BaseTable.vue'

Vue.component('SupplierElement', SupplierElement)
Vue.component('CountryElement', CountryElement)
Vue.component('BaseTable', BaseTable)
Vue.component('BaseForm', BaseForm)
Vue.component('FormElement', FormElement)
Vue.component('UserElement', UserElement)

Vue.config.productionTip = false

import App from './App.vue'

const PRIME_VUE_OBJECT = new Vue({
  store,
  router,
  render: h => h(App)
})
store.$app = PRIME_VUE_OBJECT
router.$app = PRIME_VUE_OBJECT

PRIME_VUE_OBJECT.$mount('#app')
window.PRIME_VUE_OBJECT = PRIME_VUE_OBJECT
