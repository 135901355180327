<template>
  <b-form-group v-bind="commonFormGroupProps">
    <MultiSelectForm
        :defaultOptions="item.data"
        :trackBy="item.keyValue"
        :label="item.keyText"
        :searchUrl="item.url"
        :value="selectedValues"
        @input="handleInput"
        :placeholderText="item.placeholder || 'Select items'"
        :labelSelectedText="item.selectedText || 'items selected'"
    >
      <template v-slot:multi-select__selected="{ option }">
        <slot :name="`multi-select__selected(${filterKey})`" :option="option" />
      </template>
      <template v-slot:multi-select__text="{ option }">
        <slot :name="`multi-select__text(${filterKey})`" :option="option" />
      </template>
      <template v-slot:multi-select__one_selection="{ item }">
        <slot :name="`multi-select__one_selection(${filterKey})`" :item="item" />
      </template>
    </MultiSelectForm>
  </b-form-group>
</template>

<script>
import MultiSelectForm from '@/main/custom-components/MultiSelectForm.vue'
import { formMixin } from '@/mixins/formMixin'

export default {
  components: { MultiSelectForm },
  mixins: [formMixin],
  props: ['item', 'value', 'filterKey'],
  computed: {
    commonFormGroupProps () {
      return {
        ...this.getCommonFormGroupProps()
      }
    },
    selectedValues () {
      return this.item.data.filter(option => this.value.some(val => String(val) === String(option[this.item.keyValue]))
      )
    }
  },
  methods: {
    handleInput (values) {
      this.$emit('input', values.map(v => v[this.item.keyValue]))
    }
  }
}
</script>