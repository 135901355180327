<template>
  <b-form-group v-bind="commonFormGroupProps">
    <template #label>
      <span v-if="item.htmlLabel" v-html="item.htmlLabel" />
      <span v-else>{{ item.label }}</span>
    </template>

    <b-form-checkbox-group
        :checked="Array.isArray(value) ? value : [value]"
        @input="$emit('input', $event)"
        :stacked="item.stacked"
        :key="getKey"
    >
      <b-form-checkbox
          v-for="(option, index) in filteredOptions"
          :key="index"
          :value="option.value"
          :class="[item.refresh_uuid, {'select-options': option.options}]"
          @change="$emit('change', value)"
      >
        {{ option.text }}
        <span v-if="option.appendHTML" v-html="option.appendHTML" />
        <b-form-select
            v-if="option.options"
            :options="option.options"
            :value="getInitialOption(option)"
            @change="handleSelectChange($event, option)"
            style="width: 100px;"
        />
      </b-form-checkbox>
    </b-form-checkbox-group>
  </b-form-group>
</template>

<script>
import { formMixin } from '@/mixins/formMixin'

export default {
  mixins: [formMixin],
  props: ['item', 'value', 'filterKey'],
  computed: {
    filteredOptions () {
      return this.filterOptions(this.item.data)
    },
    commonFormGroupProps () {
      return {
        ...this.getCommonFormGroupProps()
      }
    },
    getKey () {
      // eslint-disable-next-line no-constant-condition
      return `checkboxes-${this.item.refresh_uuid}` ? `${this.filterKey}-${this.item.refresh_uuid}` : this.filterKey
    }
  },
  methods: {
    getInitialOption (option) {
      if (option.options.length && Array.isArray(this.value)) {
        return this.value.find(x => option.options.includes(x)) || option.default_options
      }
    },
    handleSelectChange (selectedValue, option) {
      let newValue = []
      if (this.value && this.value.length) {
        newValue = this.value.filter(x => !option.options.includes(x))
      }

      newValue.push(selectedValue)
      if (!newValue.includes(option.value)) {
        newValue.push(option.value)
      }

      this.$emit('input', newValue)
      this.$nextTick(() => {
        this.$emit('change', option.value)
      })
    }
  }
}
</script>