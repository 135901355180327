<template>
  <validation-provider :rules="item.rules || null" v-slot="validationContext">
    <b-form-group v-bind="commonFormGroupProps">
      <b-form-select
          :value="value"
          @input="$emit('input', $event)"
          @change="$emit('change', $event)"
          :state="getValidationState(validationContext)"
          :disabled="item.isDisabled"
      >
        <b-form-select-option
            v-for="(option, index) in item.data"
            :key="index"
            :value="getSelectValue(option, item.keyValue)"
        >
          {{ getTextValue(option, item.keyText) }}
        </b-form-select-option>
      </b-form-select>
    </b-form-group>
  </validation-provider>
</template>

<script>
import { formMixin } from '@/mixins/formMixin'
import {ValidationProvider} from 'vee-validate'

export default {
  mixins: [formMixin],
  props: ['item', 'value'],
  components: {ValidationProvider},
  computed: {
    commonFormGroupProps () {
      return {
        ...this.getCommonFormGroupProps()
      }
    }
  },
  methods: {
    getValidationState ({ valid }) {
      return valid ? null : false
    }
  }
}
</script>