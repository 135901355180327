<template>
  <b-form-checkbox
      :checked="value"
      @input="$emit('input', $event)"
      @change="$emit('change', $event)"
      v-bind="commonFormGroupProps"
  >
    <span v-if="item.label">{{ item.label }}</span>
    <span v-else>{{ value ? 'Yes' : 'No' }}</span>
  </b-form-checkbox>
</template>

<script>
import {formMixin} from '@/mixins/formMixin'

export default {
  mixins: [formMixin],
  props: ['item', 'value'],
  computed: {
    commonFormGroupProps () {
      return {
        ...this.getCommonFormGroupProps()
      }
    }
  }
}
</script>

<style scoped>
.custom-switch {
  padding-left: 3.5rem;
}
</style>