<template>
  <b-form-group v-bind="commonFormGroupProps">
    <b-input-group class="no-shadow">
      <template v-if="item.append" #append>
        <b-form-input
            :value="models[item.append]"
            :placeholder="item.appendPlaceHolder"
            @input="$emit('input', $event, item.append)"
            @change="$emit('change', $event, item.append)"
            style="width: 60px; position: relative; top: -8px"
        />
      </template>

      <b-form-radio-group
          :checked="value"
          @input="$emit('input', $event)"
          :stacked="item.stacked"
      >
        <b-form-radio
            v-for="(option, index) in filteredOptions"
            :key="index"
            :value="option.value"
            @change="$emit('change', value)"
        >
          {{ option.text }}
          <span v-if="option.appendHTML" v-html="option.appendHTML" />
        </b-form-radio>
      </b-form-radio-group>
    </b-input-group>

    <MultiSelectForm
        v-if="item.appendOptions"
        :defaultOptions="item.options"
        :trackBy="item.optionTrackBy"
        :label="item.optionLabel"
        :value="models[item.optionModel]"
        @input="val => $emit('input', val, item.optionModel)"
        placeholderText="Pick Response Status"
    >
      <template v-slot:multi-select__selected="{option}">
        <slot :name="`multi-select__selected(${filterKey})`" :option="option" />
      </template>
      <template v-slot:multi-select__text="{option}">
        <slot :name="`multi-select__text(${filterKey})`" :option="option" />
      </template>
    </MultiSelectForm>
  </b-form-group>
</template>

<script>
import { formMixin } from '@/mixins/formMixin'
import MultiSelectForm from '@/main/custom-components/MultiSelectForm.vue'

export default {
  components: { MultiSelectForm },
  mixins: [formMixin],
  props: ['item', 'value', 'models', 'filterKey'],
  computed: {
    filteredOptions () {
      return this.filterOptions(this.item.data)
    },
    commonFormGroupProps () {
      return {
        ...this.getCommonFormGroupProps()
      }
    }
  }
}
</script>