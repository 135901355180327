<template>
  <b-form-group
      v-bind="commonFormGroupProps"
      :key="dateRangeKey"
  >
    <date-range
        :value="value"
        @input="$emit('input', $event)"
        :enable-time="item.enableTime"
    />
  </b-form-group>
</template>

<script>
import DateRange from '@/main/custom-components/DateRange.vue'
import { formMixin } from '@/mixins/formMixin'

export default {
  components: { DateRange },
  mixins: [formMixin],
  props: ['item', 'value', 'filterKey'],
  computed: {
    commonFormGroupProps () {
      return {
        ...this.getCommonFormGroupProps()
      }
    },
    dateRangeKey () {
      return `daterange-${this.item.refresh_uuid || this.filterKey}`
    }
  }
}
</script>