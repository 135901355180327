<template>
  <b-form-group v-bind="commonFormGroupProps">
    <b-form-datepicker
        :value="value"
        @input="$emit('input', $event)"
        :reset-button="item.resetButton"
        :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit' }"
        locale="en"
    />
  </b-form-group>
</template>

<script>
import { formMixin } from '@/mixins/formMixin'

export default {
  mixins: [formMixin],
  props: ['item', 'value'],
  computed: {
    commonFormGroupProps () {
      return {
        ...this.getCommonFormGroupProps()
      }
    }
  }
}
</script>