//Sub permission: check \App\Lib\AdminPermissions.php
import routerConfigs from '../router-configs/hcRouterConfigs'

export default [
  {
    title: 'Hey Cash',
    icon: 'DollarSignIcon',
    children: [
      {
        url: routerConfigs.HC_AFFILIATE_PARTNERS.path,
        title: 'Affiliate Partners',
        icon: 'UserPlusIcon',
        route: routerConfigs.HC_AFFILIATE_PARTNERS.name,
        sub_permissions: [
          {
            id: routerConfigs.HC_AFFILIATE_PARTNERS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.HC_BONUS_CODES.path,
        title: 'Bonus Codes',
        icon: 'GiftIcon',
        route: routerConfigs.HC_BONUS_CODES.name,
        sub_permissions: [
          {
            id: routerConfigs.HC_BONUS_CODES.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.HC_BONUS_CODE_REDEMPTIONS.path,
        title: 'Bonus Code Redemptions',
        icon: 'DollarSignIcon',
        route: routerConfigs.HC_BONUS_CODE_REDEMPTIONS.name,
        sub_permissions: [
          {
            id: routerConfigs.HC_BONUS_CODE_REDEMPTIONS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.HC_LOG_SPEND.path,
        title: 'Log Spend',
        icon: 'DollarSignIcon',
        route: routerConfigs.HC_LOG_SPEND.name,
        sub_permissions: [
          {
            id: routerConfigs.HC_LOG_SPEND.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.HC_USERS.path,
        title: 'Users',
        icon: 'UsersIcon',
        route: routerConfigs.HC_USERS.name,
        sub_permissions: [
          {
            id: routerConfigs.HC_USERS.name,
            text: 'Main',
            main: true
          },
          {
            id: 'hc_users_export',
            text: 'HC Users Export',
            always_available_for_ids: [1, 9]
          }
        ]
      },
      {
        title: 'HC User details',
        hidden: true,
        sub_permissions: [
          {
            id: routerConfigs.HC_USER_DETAILS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.HC_TRANSACTIONS.path,
        title: 'Transactions',
        icon: 'DatabaseIcon',
        route: routerConfigs.HC_TRANSACTIONS.name,
        sub_permissions: [
          {
            id: routerConfigs.HC_TRANSACTIONS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.HC_CLAIMS.path,
        title: 'Claims',
        icon: 'CheckSquareIcon',
        route: routerConfigs.HC_CLAIMS.name,
        sub_permissions: [
          {
            id: routerConfigs.HC_CLAIMS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.HC_COHORT_ANALYSIS.path,
        title: 'Cohort Analysis',
        icon: 'LayoutIcon',
        route: routerConfigs.HC_COHORT_ANALYSIS.name,
        sub_permissions: [
          {
            id: routerConfigs.HC_COHORT_ANALYSIS.name,
            text: 'Main',
            main: true
          },
          {
            id: 'hc_cohort_analysis_export',
            text: 'HC Cohort Analysis Export',
            always_available_for_ids: [1, 9]
          }
        ]
      },
      {
        url: routerConfigs.HC_LEADERBOARDS.path,
        title: 'Leaderboards',
        icon: 'BarChart2Icon',
        route: routerConfigs.HC_LEADERBOARDS.name,
        sub_permissions: [
          {
            id: routerConfigs.HC_LEADERBOARDS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.HC_LEVELS.path,
        title: 'Levels',
        icon: 'BarChartIcon',
        route: routerConfigs.HC_LEVELS.name,
        sub_permissions: [
          {
            id: routerConfigs.HC_LEVELS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.HC_SETTINGS.path,
        title: 'Settings',
        icon: 'SettingsIcon',
        route: routerConfigs.HC_SETTINGS.name,
        always_available_for_ids: [1, 5, 6],
        sub_permissions: [
          {
            id: routerConfigs.HC_SETTINGS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.HC_TRIGGERS.path,
        title: 'Triggers',
        icon: 'DiscIcon',
        route: routerConfigs.HC_TRIGGERS.name,
        sub_permissions: [
          {
            id: routerConfigs.HC_TRIGGERS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.HC_PERFORMANCE.path,
        title: 'Performance',
        icon: 'PieChartIcon',
        route: routerConfigs.HC_PERFORMANCE.name,
        sub_permissions: [
          {
            id: routerConfigs.HC_PERFORMANCE.name,
            text: 'Main',
            main: true
          },
          {
            id: 'hc_performance_export',
            text: 'HC Performance Export',
            always_available_for_ids: [1, 9]
          }
        ]
      },
      {
        url: routerConfigs.HC_REWARDS.path,
        title: 'Rewards',
        icon: 'AwardIcon',
        route: routerConfigs.HC_REWARDS.name,
        sub_permissions: [
          {
            id: routerConfigs.HC_REWARDS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.HC_EMAILS.path,
        title: 'E-Mails',
        icon: 'MailIcon',
        route: routerConfigs.HC_EMAILS.name,
        sub_permissions: [
          {
            id: routerConfigs.HC_EMAILS.name,
            text: 'Main',
            main: true
          }
        ]
      },
      {
        url: routerConfigs.HC_OFFER_WALL_PERFORMANCE.path,
        title: 'Offerwall Performance',
        icon: 'CpuIcon',
        route: routerConfigs.HC_OFFER_WALL_PERFORMANCE.name,
        sub_permissions: [
          {
            id: routerConfigs.HC_OFFER_WALL_PERFORMANCE.name,
            text: 'Main',
            main: true
          }
        ]
      }
    ]
  }
]