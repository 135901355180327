<template>
  <b-form-group v-bind="commonFormGroupProps">
    <b-input-group>
      <template v-if="item.append" #append>
        <div v-if="item.appendText" class="text-center margin-auto">
          {{ item.appendText }}
        </div>
        <b-form-input
            v-else
            :value="models[item.append]"
            :placeholder="item.appendPlaceHolder"
            @input="$emit('input', $event, item.append)"
            style="border-top-left-radius: 0; width: 60px;"
        />
      </template>

      <b-form-input
          :value="value"
          @input="$emit('input', $event)"
          @change="$emit('change', models[filterKey])"
          :placeholder="item.placeHolder"
          @keypress.enter.exact="$emit('search')"
          :class="{ 'rounded-left' : item.append }"
      />

      <slot :name="`afterInput(${filterKey})`" :item="item" />
    </b-input-group>
  </b-form-group>
</template>

<script>
import { formMixin } from '@/mixins/formMixin'

export default {
  mixins: [formMixin],
  props: ['item', 'value', 'models', 'filterKey'],
  computed: {
    commonFormGroupProps () {
      return {
        ...this.getCommonFormGroupProps()
      }
    }
  }
}
</script>