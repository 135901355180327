export const formMixin = {
  methods: {
    getSelectValue (item, key) {
      return typeof key === 'string' ? item[key] : item.value ?? item.id
    },
    getTextValue (item, key) {
      return typeof key === 'string' ? item[key] : item.text ?? item.name
    },
    filterOptions (data) {
      return data.filter(item => (item.onlyShowForIds?.includes(this.$store.state.auth.AppActiveAdmin?.id) ?? true) &&
                (item.show ?? true)
      )
    },
    getLayoutProps (prefix) {
      const sizes = ['', 'sm', 'md', 'lg', 'xl']
      return sizes.reduce((acc, size) => {
        const key = size ? `${prefix}-${size}` : prefix
        acc[key] = typeof this.item[key] === 'number' ? this.item[key] : null
        return acc
      }, {})
    },
    getCommonFormGroupProps () {
      return {
        class: this.item.class,
        label: this.item.label,
        style: this.item.style,
        disabled: this.item.isDisabled,
        ...this.getLayoutProps('label-cols'),
        ...this.getLayoutProps('content-cols')
      }
    }
  }
}