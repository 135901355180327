var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.withoutLinked && _vm.canAccess('supplier_details'))?_c('router-link',{attrs:{"to":{
            name: 'supplier_details',
            params: { supplierId: _vm.id },
          }}},[_c('div',{staticClass:"supplier-item d-flex align-items-center"},[_c('div',{class:[
            'supplier-branch',
            'supplier-' + _vm.mappedId,
            _vm.textColorClass
          ]},[_vm._v(_vm._s(_vm.shortName))]),(_vm.name)?_c('div',{staticClass:"supplier-name"},[_vm._v(_vm._s(_vm.name))]):_vm._e()])]):_c('div',{staticClass:"supplier-item d-flex align-items-center"},[_c('div',{class:[
          'supplier-branch',
          'supplier-' + _vm.mappedId,
          _vm.textColorClass
        ]},[_vm._v(_vm._s(_vm.shortName))]),(_vm.name)?_c('div',{staticClass:"supplier-name"},[_vm._v(_vm._s(_vm.name))]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }