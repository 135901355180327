<template>
  <b-form-group v-bind="commonFormGroupProps">
    <b-form-textarea
        :value="value"
        @input="$emit('input', $event)"
        :rows="item.rows || 5"
        :disabled="item.isDisabled"
    />
  </b-form-group>
</template>

<script>
import { formMixin } from '@/mixins/formMixin'

export default {
  mixins: [formMixin],
  props: ['item', 'value'],
  computed: {
    commonFormGroupProps () {
      return {
        ...this.getCommonFormGroupProps()
      }
    }
  }
}
</script>