<template>
  <b-form-group v-bind="commonFormGroupProps">
    <flat-pickr
        :value="value"
        @input="$emit('input', $event)"
        :config="dateConfig"
        class="form-control"
        :placeholder="item.placeHolder"
    />
  </b-form-group>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import { formMixin } from '@/mixins/formMixin'

export default {
  components: { flatPickr },
  mixins: [formMixin],
  props: ['item', 'value'],
  computed: {
    commonFormGroupProps () {
      return {
        ...this.getCommonFormGroupProps()
      }
    },
    dateConfig () {
      return {
        enableTime: true,
        dateFormat: 'Y-m-d H:i:S',
        ...this.item.config
      }
    }
  }
}
</script>