import axiosObject from './axios'
import axiosChatObject from '@/main/axiosChat'
import router from './router'

export default {
  namespaced: true,
  state: {
    AppActiveAdmin: null,
    isLoggedIn: false,
    isAcrossLogin: false,
    isAppReady: false,
    menuNumberFresh: 0
  },
  mutations: {
    //this.$store.commit('auth/*', data);
    UPDATE_ADMIN_INFO (state, payload) {
      state.AppActiveAdmin = payload
      state.isLoggedIn = true
    },
    CLEAR_ADMIN_INFO (state) {
      state.AppActiveAdmin = null
      state.isLoggedIn = false
      state.isAcrossLogin = false
      state.modules = false
    },
    UPDATE_ACROSS_LOGIN (state, payload) {
      state.isAcrossLogin = payload
    },
    UPDATE_IS_APP_READY (state, payload) {
      state.isAppReady = payload
    },
    REFRESH_MENU (state) { state.menuNumberFresh++ }
  },
  actions: {
    //this.$store.dispatch('auth/*', data);
    init ({dispatch, commit, state}) {
      //Load user from local storage
      const userInfo = JSON.parse(localStorage.getItem(window.CONST_LOCAL_STORAGE_KEY_ADMIN_INFO))
      const apiToken = localStorage.getItem(window.CONST_LOCAL_STORAGE_KEY_API_TOKEN)
      const chatAuthToken = localStorage.getItem(window.CONST_LOCAL_STORAGE_KEY_CHAT_AUTH_TOKEN)
      if (
        userInfo &&
                apiToken &&
                (
                  parseInt(userInfo.role) === 1 ||
                    userInfo.permissions.modules.length > 0
                )
      ) {
        if (localStorage.getItem(window.CONST_LOCAL_STORAGE_KEY_ORIGIN_ADMIN_INFO)) {
          commit('UPDATE_ACROSS_LOGIN', true)
        }
        commit('UPDATE_ADMIN_INFO', userInfo)
        axiosObject.updateAuthentication(apiToken)
        commit('UPDATE_IS_APP_READY', true)
      } else if (apiToken) {
        axiosObject.updateAuthentication(apiToken)
        axiosObject.get('/api/me')
          .then((response) => {
            const returnData = response.data
            const hasMainPage = typeof returnData.permissions.main_page === 'string' &&
                            returnData.permissions.main_page !== ''
            const isValidModules = returnData.role === window.CONST_ADMIN_ROLE_SUPER_ADMIN ||
                            (returnData.permissions.modules instanceof Array && returnData.permissions.modules.length > 0)
            if (isValidModules) {
              localStorage.setItem(window.CONST_LOCAL_STORAGE_KEY_ADMIN_INFO, JSON.stringify(returnData))
              commit('UPDATE_ADMIN_INFO', returnData)
              router.redirectToMainPage('Does not have admin info in local storage')
            } else {
              dispatch('logout')
            }
            commit('UPDATE_IS_APP_READY', true)
          })
          .catch((errors) => {
            dispatch('logout')
            commit('UPDATE_IS_APP_READY', true)
          })
      } else {
        commit('UPDATE_IS_APP_READY', true)
      }

      if (chatAuthToken) {
        axiosChatObject.updateAuthentication(chatAuthToken)
      }
    },
    updateApiToken ({ dispatch }, apiToken) {
      localStorage.setItem(window.CONST_LOCAL_STORAGE_KEY_API_TOKEN, apiToken)
      axiosObject.updateAuthentication(apiToken)
    },
    logout ({commit, dispatch, getters}) {
      commit('CLEAR_ADMIN_INFO')
      axiosObject.removeAuthentication()
      axiosChatObject.removeAuthentication()
      localStorage.removeItem(window.CONST_LOCAL_STORAGE_KEY_ADMIN_INFO)
      localStorage.removeItem(window.CONST_LOCAL_STORAGE_KEY_API_TOKEN)
      localStorage.removeItem(window.CONST_LOCAL_STORAGE_KEY_CHAT_AUTH_TOKEN)
      router.push({path: '/login'})
    },
    login ({ commit, dispatch }, { apiToken, chatAuthToken, admin }) {
      // Commit admin info to Vuex store
      commit('UPDATE_ADMIN_INFO', admin)

      // Store authentication details in localStorage
      localStorage.setItem(window.CONST_LOCAL_STORAGE_KEY_ADMIN_INFO, JSON.stringify(admin))
      localStorage.setItem(window.CONST_LOCAL_STORAGE_KEY_API_TOKEN, apiToken)
      localStorage.setItem(window.CONST_LOCAL_STORAGE_KEY_CHAT_AUTH_TOKEN, chatAuthToken)

      // Update authentication tokens for API requests
      axiosObject.updateAuthentication(apiToken)
      axiosChatObject.updateAuthentication(chatAuthToken)

      // Redirect logic
      setTimeout(() => {
        const defaultRoute = '/surveys'
        const routeExists = router.getRoutes().some(route => route.path === '/')

        // Check for redirect query parameter
        const redirectPath = router.currentRoute.query.redirect ? decodeURIComponent(router.currentRoute.query.redirect) : (routeExists ? '/' : defaultRoute)

        router.push({ path: redirectPath })
      }, 500)
    },
    switchToOtherAccount ({dispatch, commit}, {accountInfo, accountApiToken}) {
      if (accountInfo) {
        const userInfo = JSON.parse(localStorage.getItem(window.CONST_LOCAL_STORAGE_KEY_ADMIN_INFO))
        const apiToken = localStorage.getItem(window.CONST_LOCAL_STORAGE_KEY_API_TOKEN)
        const originalUserInfo = JSON.parse(localStorage.getItem(window.CONST_LOCAL_STORAGE_KEY_ORIGIN_ADMIN_INFO))
        const originalApiToken = localStorage.getItem(window.CONST_LOCAL_STORAGE_KEY_ORIGIN_API_TOKEN)
        const isSwitchBack = originalUserInfo && parseInt(accountInfo.id) === parseInt(originalUserInfo.id)
        if (isSwitchBack) {
          localStorage.removeItem(window.CONST_LOCAL_STORAGE_KEY_ORIGIN_ADMIN_INFO)
          localStorage.removeItem(window.CONST_LOCAL_STORAGE_KEY_ORIGIN_API_TOKEN)
          commit('UPDATE_ACROSS_LOGIN', false)
        } else {
          if (!originalUserInfo) {
            localStorage.setItem(window.CONST_LOCAL_STORAGE_KEY_ORIGIN_ADMIN_INFO, JSON.stringify(userInfo))
            localStorage.setItem(window.CONST_LOCAL_STORAGE_KEY_ORIGIN_API_TOKEN, apiToken)
          }
          commit('UPDATE_ACROSS_LOGIN', true)
        }
        localStorage.setItem(window.CONST_LOCAL_STORAGE_KEY_ADMIN_INFO, JSON.stringify(accountInfo))
        localStorage.setItem(window.CONST_LOCAL_STORAGE_KEY_API_TOKEN, accountApiToken)
        localStorage.setItem(window.CONST_LOCAL_STORAGE_KEY_ORIGIN_CURRENT_MENU, this.$app.$router.currentRoute.path)
        dispatch('init')
        this.$app.$router.redirectToMainPage('switch to other account')
        this.$app.refreshMenu()
        this.$app.$router.go()
      }
    },
    backToAdmin ({dispatch, commit}) {
      const originalUserInfo = JSON.parse(localStorage.getItem(window.CONST_LOCAL_STORAGE_KEY_ORIGIN_ADMIN_INFO))
      const originalApiToken = localStorage.getItem(window.CONST_LOCAL_STORAGE_KEY_ORIGIN_API_TOKEN)
      const originalCurrentMenu = localStorage.getItem(window.CONST_LOCAL_STORAGE_KEY_ORIGIN_CURRENT_MENU)
      if (parseInt(originalUserInfo.id) > 0) {
        commit('UPDATE_ACROSS_LOGIN', false)
        localStorage.removeItem(window.CONST_LOCAL_STORAGE_KEY_ORIGIN_ADMIN_INFO)
        localStorage.removeItem(window.CONST_LOCAL_STORAGE_KEY_ORIGIN_API_TOKEN)
        localStorage.setItem(window.CONST_LOCAL_STORAGE_KEY_ADMIN_INFO, JSON.stringify(originalUserInfo))
        localStorage.setItem(window.CONST_LOCAL_STORAGE_KEY_API_TOKEN, originalApiToken)
        dispatch('init')
        this.$app.refreshMenu()
      }

      if (originalCurrentMenu) {
        this.$app.$router.push(originalCurrentMenu)
      }

      this.$app.$router.go()
    },
    updateAdminInfo ({commit}, payload) {
      localStorage.setItem(window.CONST_LOCAL_STORAGE_KEY_ADMIN_INFO, JSON.stringify(payload))
      commit('UPDATE_ADMIN_INFO', payload)
      this.$app.$router.redirectToMainPage('update Admin info')
      this.$app.refreshMenu()
    }
  },
  getters: {
    //this.$store.getters['auth/isLoggedIn']
    isLoggedIn: (state) => state.isLoggedIn,
    admin: (state) => state.AppActiveAdmin,
    isAcrossLogin: (state) => state.isAcrossLogin,
    isAppReady: (state) => state.isAppReady,
    modules: (state) => state.modules
  }
}
